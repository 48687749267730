<template>
    <div>
        <el-container class="ova-y">
            <el-main class="p0">
                <el-row>
                    <el-col :span="24">
                        <div class="box" ref="init">
                            <div class="box-content">
                                <el-form :inline="true" class="form-inline formBox">
                                    <el-form-item label="选择任务">
                                        <el-cascader size="medium" @change="changeTask" style="width: 220px" v-model="checkedMask" :options="jsxzList" placeholder="请选择" :props="{ expandTrigger: 'hover', multiple: false }" collapse-tags>
                                        </el-cascader>
                                    </el-form-item>
                                    <el-form-item label="日期范围">
                                        <el-date-picker
                                                align="right"
                                                unlink-panels
                                                v-model="date"
                                                type="daterange"
                                                range-separator="至"
                                                start-placeholder="开始日期"
                                                :picker-options="pickerOptions"
                                                @change="chooseTime"
                                                value-format="yyyy-MM-dd"
                                                end-placeholder="结束日期">
                                        </el-date-picker>
                                    </el-form-item>
<!--                                    <el-form-item>-->
<!--                                        <el-button type="primary"  style="margin-top:19px"  size="medium" icon="el-icon-search" @click="currentPage = 1;getData();">查询</el-button>-->
<!--                                        <el-button type="primary" size="medium" plain @click="clearCondition();search();" icon="el-icon-delete">清除</el-button>-->
<!--                                    </el-form-item>-->
                                </el-form>

                            </div>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="plr12">
                    <el-col :span="24">
                        <div class="box">
                            <div class="box-title">

                                <h3 class="tit">工作完成情况</h3>
                            </div>
                            <div class="box-content">
                                <div class="tj">
                                    <div class="total-item total-item-1">
                                            <img src="../../assets/icon/tj1.png" alt="">
                                        <div class="t">
                                            <h5>合计<small>（个）</small></h5>
                                            <b class="date-num">{{ total }}</b>
                                        </div>
                                    </div>

                                    <div class="total-item total-item-2">
                                        <img src="../../assets/icon/tj2.png" alt="">
                                        <div class="t">
                                            <h5>已上传地市<small>（个）</small></h5>
                                            <b class="date-num">{{ upload }}</b>
                                        </div>
                                    </div>


                                    <div class="total-item total-item-4">
                                        <img src="../../assets/icon/tj4.png" alt="">
                                        <div class="t">
                                            <h5>未上传地市<small>（个）</small></h5>
                                            <b class="date-num">{{NotUploadNum}}</b>
                                        </div>
                                    </div>

                                </div>
                                <div style="overflow-y: scroll" :style="{height:tableHeight+'px'}" >
<!--                                    <el-tree-->
<!--                                            :default-expanded-keys="[1]"-->
<!--                                            :data="treeData"-->
<!--                                            node-key="id"-->
<!--                                            :expand-on-click-node="false">-->
<!--                                        <div class="line" slot-scope="{ node,data }">-->
<!--                                            <div :class="data.Children ? 'big' : 'small'">{{ data.Name }}</div>-->
<!--                                            <el-tag  :class="data.type == '1' ? 'upload' : 'notUpload'"  :type="data.Type == '1' ? 'success' : 'danger'">{{data.Type}}</el-tag>-->
<!--                                            <div v-if="data.Children" class="first">-->
<!--                                                <div>合计: <span class="number">{{ data.Total}}</span></div>-->
<!--                                                <div style="margin-left: 32px">已上传: <span class="number">{{ data.UploadNum}}</span></div>-->
<!--                                                <div style="margin-left: 32px">未上传: <span class="number">{{ data.NotUploadNum }}</span></div>-->
<!--                                            </div>-->
<!--                                        </div>-->
<!--                                    </el-tree>-->
                                    <div style="display: flex;flex-wrap: wrap;justify-content: space-around">
                                        <div class="col" v-for="(item,index) in treeData" :key="index">
                                            <div class="col-1">
                                                <el-divider>{{item.Name}}</el-divider>
                                                <div style="display: flex;align-items: center;justify-content: space-between">
                                                    <div>全部: {{item.Total}} 个</div>
                                                    <div>已上传: {{item.UploadNum}} 个</div>
                                                    <div>未上传: {{item.NotUploadNum}} 个</div>
                                                </div>
                                                <div v-for="(subItem,subIndex) in item.Children" :key="subIndex" style="display: flex;align-items: center;height: 40px">
                                                    <div style="width: 100px">{{subItem.Name}}</div>
                                                    <div class="small">
                                                        <el-tag  :class="subItem.Type == 1 ? 'upload1' : 'notUpload1'" :type="subItem.Type == 1 ? 'success' : 'danger'">
                                                            <span v-if="subItem.Type == 0">未上传</span>
                                                            <span v-else>已上传</span>
                                                        </el-tag>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </el-col>
                </el-row>
            </el-main>
        </el-container>
    </div>
</template>

<script>
    export default {
        name: "statistics",
        data(){
            return{
                defaultTime:[],
                tableHeight:0, //表格高度
                total:0,
                upload:0,
                NotUploadNum:0,
                treeData:[],
                pickerOptions: {
                    shortcuts: [{
                        text: '最近三天',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 3);
                            picker.$emit('pick', [start, end]);
                        }
                    },{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                checkedMask:['前期任务'],
                date:[],
                jsxzList: [
                    { value: "前期任务", label: "前期任务", disabled: false },
                    { value: "确认函上传", label: "确认函上传", disabled: false },
                    { value: "计划及确认文件", label: "计划及确认文件", disabled: false },
                ],
            }
        },
        mounted() {
            var that = this
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
           this.date = [start,end]
            console.log(this.defaultTime)


            // this.nowYear = new Date().getFullYear()
            let height = this.$refs.init.offsetHeight;
            setTimeout(() => {
                that.tableHeight = document.body.offsetHeight - height - 180;
            }, 100);
            this.http.post('/api/Sys_User/getCurrentUserInfo',{}).then(res=>{
                this.roleName = res.data.roleName
                this.isAdmin = res.data.roleName == '超级管理员' ||  res.data.isMain  == 1 ? true : false
            })
            // this.getCompanyList()
            this.getData()
        },
        methods:{
            chooseTime(val){
                this.getData()
            },
            changeTask(val){
               this.getData()
            },
            getData(){
                var params ={
                    wheres: JSON.stringify([{Name:'StartDate',Value:this.date[0],DisplayType:'like'},{Name:'EndDate',Value:this.date[1],DisplayType:'like'} ]),
                }

                if (this.checkedMask == '前期任务'){
                    this.http.post('/api/Plan_road_confirmation/GetTaskStatisticsOfFillIn',params).then(res=>{
                        this.treeData = res
                        var num = 0
                        var upload = 0
                        var NotUploadNum = 0
                        this.treeData.map((item,index)=>{
                            console.log(item)
                            num+= item.Total
                            upload += item.UploadNum
                            NotUploadNum += item.NotUploadNum
                        })
                       this.total = num
                       this.upload = upload
                       this.NotUploadNum = NotUploadNum
                    })
                }else if(this.checkedMask == '确认函上传'){
                    this.http.post('/api/Plan_road_confirmation/GetTaskStatisticsOfConfirmation',params).then(res=>{
                        this.treeData = res
                        var num = 0
                        var upload = 0
                        var NotUploadNum = 0
                        this.treeData.map((item,index)=>{
                            console.log(item)
                            num+= item.Total
                            upload += item.UploadNum
                            NotUploadNum += item.NotUploadNum
                        })
                        this.total = num
                        this.upload = upload
                        this.NotUploadNum = NotUploadNum
                    })
                }else if(this.checkedMask == '计划及确认文件'){
                    this.http.post('/api/Plan_road_confirmation/GetTaskStatisticsOfApply',params).then(res=>{
                        this.treeData = res
                        var num = 0
                        var upload = 0
                        var NotUploadNum = 0
                        this.treeData.map((item,index)=>{
                            console.log(item)
                            num+= item.Total
                            upload += item.UploadNum
                            NotUploadNum += item.NotUploadNum
                        })
                        this.total = num
                        this.upload = upload
                        this.NotUploadNum = NotUploadNum
                    })
                }
            },
        }

    }
</script>

<style scoped lang="less">
.line{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
    .big{
        font-size: 20px;
        width: 200px;
        color: #333;
    }
    .small{
        width: 213px;
        font-size: 16px;
    }
    .first{
        margin-left: 32px;
        width: 100px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }
    .number{
        font-size: 16px;
        color: #dd6161;
        font-weight: 500;
    }
    .upload{
        margin-left: 80px;
    }
    .notUpload{
        margin-left: 165px;

    }
    /deep/.el-tree-node__content{
        height: 40px;
    }
.col{
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 60px;
    .col-1{
        padding:10px 20px 10px 20px;
        width: 330px;
        background: #F2F8FE;
        border-radius: 5px;
    }
}
.deliver{
    margin: 15px 0;
    width: 100%;
    height: 1px;
    border-bottom: 1px dotted #999999;
}
.el-divider__text{
    background: #F2F8FE;
}


.upload1{
    margin-left: 25px;
}
.notUpload1{
    margin-left: 160px;

}

.col:last-child:nth-child(4n - 1) {
    margin-right: calc(24% + 4% / 3);
}
/* 如果最后⼀⾏是2个元素 */
.col:last-child:nth-child(4n - 2) {
    margin-right: calc(48% + 8% / 3)
}
</style>
